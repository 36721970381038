import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/commonservice/api/apiservice';
import { MessagehandlingService } from 'src/app/commonservice/message/message-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent{
  loginForm!: FormGroup
  passwordType = "password"
  passwordIcon: any = "assets/images/eye_close.svg";
  constructor(private route: Router,private formBuilder: FormBuilder, public api:ApiService,private message:MessagehandlingService) { 

  this.loginForm = this.formBuilder.group({
    email: ['', [Validators.required, this.emailValidator.bind(this)]],
    password: ['', [Validators.required]],
  })
}

emailValidator(control: FormControl) {   //email validation
  if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
    return { invalidEmail: true };
  }
  return
}

hidesShowpassword() {
  this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
  this.passwordIcon = this.passwordIcon === 'assets/images/eye_close.svg' ? 'assets/images/eye_on.svg' : 'assets/images/eye_close.svg';
}

dashboard() {
  let data = {
    email: this.loginForm.value.email,
    password: this.loginForm.value.password
  }
  this.api.loginApi(data).subscribe((res: any) => {
    console.log(res)
    this.message.successMessage(res.message,2000)
    let loginUserDetail: any = []
    loginUserDetail = res?.data?.users
    localStorage.setItem('tokenC', res.data?.users?.token)
    sessionStorage.setItem('loginUserDetail', JSON.stringify(loginUserDetail))
    this.route.navigate(['/dashboard'])
  })

}
}
