import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ApiService } from 'src/app/commonservice/api/apiservice';
import { MessagehandlingService } from 'src/app/commonservice/message/message-service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  changePasswordForm!: FormGroup

  constructor(private formBuilder: FormBuilder, private api: ApiService, private message: MessagehandlingService) {
    this.changePasswordForm = formBuilder.group({
      oldPassword: ['', [Validators.required,this.noLeadingTrailingSpaces()
        
      ]],
      newPassword: ['', [Validators.required, this.noLeadingTrailingSpaces()]],
      cPassword: ['', [Validators.required, this.noLeadingTrailingSpaces()]]
    }, { validator: this.checkPasswords })


  }
  ngOnInit(): void {
  }

  checkPasswords(control: FormControl) {
    const pass = control.get('newPassword')?.value
    const confirmPass = control.get('cPassword')?.value
    if (pass != confirmPass) {
      control.get('cPassword')?.setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
    return
  }

  // noLeadingTrailingSpaces(): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     if (typeof control.value === 'string' && control.value.trim() !== control.value) {
  //       return { leadingTrailingSpaces: true };
  //     }
  //     return null;
  //   };
  // }

  noLeadingTrailingSpaces(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (typeof control.value === 'string' && this.hasLeadingTrailingOrConsecutiveSpaces(control.value)) {
        return { leadingTrailingSpaces: true };
      }
      return null;
    };
  }

  hasLeadingTrailingOrConsecutiveSpaces(value: string): boolean {
    if (value.trim() !== value) {
      return true;
    }
    if (value.includes(' ')) {
      return true;
    }

    return false;
  }

  submitPassword() {
    if (this.changePasswordForm.invalid) {
      this.message.errorMessage('Please fill all fields', 2000)
    } else {
      let data = {
        oldPassword: this.changePasswordForm.value.oldPassword,
        newPassword: this.changePasswordForm.value.newPassword,
      }
      this.api.changePassword(data).subscribe((res: any) => {
        this.message.successMessage(res.message, 1000)
        this.close()
      })
    }
  }

  close() {
    this.changePasswordForm.reset()
  }
}

