import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/commonservice/api/apiservice';
import { MessagehandlingService } from 'src/app/commonservice/message/message-service';

@Component({
  selector: 'app-dashboadinternal',
  templateUrl: './dashboadinternal.component.html',
  styleUrls: ['./dashboadinternal.component.scss']
})
export class DashboadinternalComponent implements OnInit {
  dashBoardData: any=[]

  constructor(private api: ApiService, private message: MessagehandlingService) { }

  ngOnInit(): void {
    this.getDashboardData()
  }

  getDashboardData() {
    let fd = new FormData
    this.api.dashboardData(fd).subscribe((res: any) => {
      // this.message.successMessage(res.message,1000)
      this.dashBoardData=res.data

    })
  }

}
