import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/commonservice/api/apiservice';
import { MessagehandlingService } from 'src/app/commonservice/message/message-service';
declare var $: any
@Component({
  selector: 'app-drivers-account',
  templateUrl: './drivers-account.component.html',
  styleUrls: ['./drivers-account.component.scss']
})
export class DriversAccountComponent implements OnInit {
  showModal = false;
  driverData: any = []
  selectedLimit: any = 10
  searchTerm: any;
  totalItems: any;
  currentPage: any = 1
  pageSize: any = 10
  totalpages!: any
  pageNumbers: any;
  limitedPageNumbers: any = []
  driverTotalCount: any;
  showFullContent: boolean = false;
  readMoreMessage: any = ''
  driverDetail: any = []
  showdeleteModal = false;
  id: any
  constructor(private api: ApiService, private message: MessagehandlingService) {
  }

  ngOnInit(): void {
    this.getDriverData()
  }

  getDriverData() {
    const offset = (this.currentPage - 1) * this.pageSize;
    this.api.driversData(offset, this.selectedLimit).subscribe((res: any) => {
      this.driverData = res.data.allDrivers
      this.driverTotalCount = res.data.totalCount
      this.totalItems = res.data.totalCount;
      this.totalpages = Math.ceil(this.totalItems / this.pageSize);
    })
  }

  updatePageSize(newPageSize: any) {
    this.pageSize = newPageSize.value;
    this.currentPage = 1;
    if (this.searchTerm) {
      this.api.driversData(0, this.pageSize, this.searchTerm).subscribe((res: any) => {
        this.driverData = res.data.allDrivers;
        if (res.data.totalCount >= this.pageSize) {
          this.totalItems = res.data.totalCount;
        } else {
          this.totalItems = this.pageSize;
        }
        this.totalpages = Math.ceil(this.totalItems / this.pageSize);
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      });
    } else {
      this.api.driversData(0, this.pageSize).subscribe((res: any) => {
        this.driverData = res.data.allDrivers;
        if (res.data.totalCount >= this.pageSize) {
          this.totalItems = res.data.totalCount;
        } else {
          this.totalItems = this.pageSize;
        }
        this.totalpages = Math.ceil(this.totalItems / this.pageSize);
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      });
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      const offset = (this.currentPage - 1) * this.pageSize;

      if (this.searchTerm) {
        this.api.driversData(offset, this.pageSize, this.searchTerm).subscribe((res: any) => {
          this.driverData = res.data.allDrivers;
          this.updateLimitedPageNumbers();
          this.updatePageNumbersArray();
        });
      } else {
        this.api.driversData(offset, this.pageSize).subscribe((res: any) => {
          this.driverData = res.data.allDrivers;
          this.updateLimitedPageNumbers();
          this.updatePageNumbersArray();
        });
      }
    }
  }

  nextPage() {
    if (this.currentPage < this.totalpages) {
      this.currentPage++;
      const offset = (this.currentPage - 1) * this.pageSize;

      if (this.searchTerm) {
        this.api.driversData(offset, this.pageSize, this.searchTerm).subscribe((res: any) => {
          this.driverData = res.data.allDrivers;
          this.updateLimitedPageNumbers();
          this.updatePageNumbersArray();
        });
      } else {
        this.api.driversData(offset, this.pageSize).subscribe((res: any) => {
          this.driverData = res.data.allDrivers;
          this.updateLimitedPageNumbers();
          this.updatePageNumbersArray();
        });
      }
    }
  }

  goToPage(pageNumber: number) {
    this.currentPage = pageNumber;
    const offset = (this.currentPage - 1) * this.pageSize;
    if (this.searchTerm) {
      this.api.driversData(offset, this.pageSize, this.searchTerm).subscribe((res: any) => {
        this.driverData = res.data.allDrivers;
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      });
    } else {
      this.api.driversData(offset, this.pageSize).subscribe((res: any) => {
        this.driverData = res.data.allDrivers;
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      });
    }
  }

  searchDriver() {
    this.currentPage = 1;
    let offset = 0
    this.api.driversData(offset, this.pageSize, this.searchTerm).subscribe((res: any) => {
      this.driverData = res.data.allDrivers
      this.totalItems = res.data.totalCount;
      if (res.data.totalCount) {
        this.totalpages = Math.ceil(this.totalItems / this.pageSize)
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      }
    })
  }

  updateLimitedPageNumbers() {
    this.limitedPageNumbers = this.calculateLimitedPageNumbers(this.currentPage, this.totalpages);
  }

  updatePageNumbersArray() {
    this.pageNumbers = [];
    for (let i = 1; i <= this.totalpages; i++) {
      this.pageNumbers.push(i);
    }
  }

  calculateLimitedPageNumbers(currentPage: number, totalpages: number): number[] {
    const numPagesToShow = 2;
    const limitedPageNumbers: number[] = [];
    limitedPageNumbers.push(currentPage);
    for (let i = 1; i <= numPagesToShow; i++) {
      if (currentPage - i > 0) {
        limitedPageNumbers.unshift(currentPage - i);
      }
    }
    for (let i = 1; i <= numPagesToShow; i++) {
      if (currentPage + i <= totalpages) {
        limitedPageNumbers.push(currentPage + i);
      }
    }
    return limitedPageNumbers;
  }

  detailModal() {
    this.showModal = !this.showModal;
  }

  getDriverDetail(id: any) {
    this.api.driverDetail(id).subscribe((res: any) => {
      this.driverDetail = res.data.user
    })
  }

  deleteModal(id?: any) {
    this.id = id
    this.showdeleteModal = !this.showdeleteModal;
  }

  deleteDriver() {
    this.api.deleteDriver(this.id).subscribe((res: any) => {
      this.message.successMessage(res.message, 2000)
      this.deleteModal()
      this.getDriverData()
      this.searchTerm = ''
    })
  }

  suspendStatus(value: any, id: any) {
    this.api.suspendDriver(id, { 'isBlocked': value }).subscribe((res: any) => {
      this.message.successMessage(res.message, 2000)
      this.getDriverData()
      this.searchTerm=''
    })
  }
}
