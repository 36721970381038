<div class="heading_top pl-8 text-2xl">
    <h1>Driver Account Management</h1>
</div>
<div class="mx-auto w-11/12 pt-11">
    <nav class="flex" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            <li class="inline-flex items-center">
                <a href="/dashboard"
                    class="inline-flex gap-2 cursor-pointer items-center text-base font-medium text-gray hover:text-hover_blue dark:text-gray-400">
                    <svg class="w-3 h-3 pr-3.9" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                    </svg>
                    Dashboard
                </a>
            </li>
            <li class="inline-flex items-center">
                <a href="/dashboard/drivers-account"
                    class="inline-flex gap-2 items-center cursor-pointer text-base font-medium text-gray hover:text-hover_blue dark:text-gray-400">
                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 9 4-4-4-4" />
                    </svg>
                    Driver Account Management
                </a>
            </li>

        </ol>
    </nav>
    <div class="flex gap-4 mt-4 items-center justify-center">
        <div class="rounded-2xl col-span-3 gap-3 flex items-center border border-stroke bg-white px-5 py-6 shadow-lg">
            <div class="w-20 h-20 bg-border_bg rounded-lg p-5"><img src="../../../../assets/images/total-shippers.svg"
                    alt="" /></div>
            <div class="ml-auto text-right">
                <h4 class="text-4xl font-bold">{{driverTotalCount}}</h4>
                <p class="text-base text-gray-500">Total Number of Registered Drivers</p>
            </div>
        </div>
    </div>
    <div class="relative overflow-x-auto mt-8 bg-white rounded-xl shadow-lg pb-3">
        <div class="grid grid-cols-12 p-5 items-center">
            <div class="col-span-6">
                <div class="flex items-center gap-2">
                    <span>Show</span>
                    <!--  (change)="updatePageSize($event.target)" -->
                    <span><select class="w-16 pl-2 h-8" aria-label="Default select example" [(ngModel)]="selectedLimit"
                            (change)="updatePageSize($event.target)">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>

                        </select>
                    </span>
                    <span>Entries</span>
                </div>
            </div>
            <div class="col-span-6">
                <div class="mb-3 flex items-center w-50 justify-end gap-3">
                    <label class="pe-3">Search</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" placeholder="Search...." [(ngModel)]="searchTerm"
                            (keyup.enter)="searchDriver()">
                    </div>
                </div>
            </div>
        </div>
        <table class="display w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-hidden">
            <thead class="text-xs text-gray-700 uppercase bg-dark_bg dark:dark_bg dark:dark_bg">
                <tr>
                    <th scope="col" class="px-6 py-3 text-base capitalize">
                        Name
                    <th scope="col" class="px-6 py-3 text-base capitalize">
                        Email
                    <th scope="col" class="px-6 py-3 text-base capitalize">
                        Phone Number
                    </th>
                    <th scope="col" class="px-6 py-3 text-base capitalize">
                        Address
                    </th>
                    <th scope="col" class="px-6 py-3 text-base capitalize">
                        State
                    </th>
                    <th scope="col" class="px-6 py-3 text-base capitalize">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:border-gray-700 shadow-2xl" *ngFor="let data of driverData">
                    <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                        <img *ngIf="data.profileImage === null || data.profileImage === '' || data.profileImage === undefined"
                            class="w-10 h-10 rounded-full" src="../../../../assets/images/user-vector.svg">
                        <img *ngIf="data?.profileImage" class="w-10 h-10 rounded-full" [src]="data.profileImage">

                        <div class="pl-2">
                            <div class="text-base font-semibold text-gray-900">{{data?.fullName}}</div>

                        </div>
                    </th>
                    <td class="px-6 py-4">
                        <div class="text-base font-semibold text-gray-900">{{data?.email}}</div>
                    </td>
                    <td class="px-6 py-4 font-semibold text-gray-900">
                        {{data?.mobileCountryCode}}{{data?.mobile}}
                    </td>
                    <td class="px-6 py-4 font-semibold text-gray-900">
                        {{data?.address}}
                    </td>
                    <td class="px-6 py-4 font-semibold text-gray-900">
                        {{data.state}}
                    </td>
                    <td class="px-6 py-4 ">
                        <div class="flex gap-2 items-center">
                            <span class="bg-gray-100 px-2 py-2 rounded-lg cursor-pointer"
                                (click)="getDriverDetail(data?._id)">
                                <a (click)="detailModal()">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M21 12C21 13.2 16.9706 18 12 18C7.02944 18 3 13.2 3 12C3 10.8 7.02944 6 12 6C16.9706 6 21 10.8 21 12Z"
                                            stroke="#30C084" stroke-width="2" />
                                        <path
                                            d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                                            stroke="#30C084" stroke-width="2" />
                                    </svg>
                                </a>
                            </span>
                            <span class="bg-gray-100 px-2 py-2 rounded-lg cursor-pointer">
                                <a (click)="deleteModal(data?._id)">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5 7H19M10 10V18M14 10V18M10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7H9V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3ZM6 7H18V20C18 20.2652 17.8946 20.5196 17.7071 20.7071C17.5196 20.8946 17.2652 21 17 21H7C6.73478 21 6.48043 20.8946 6.29289 20.7071C6.10536 20.5196 6 20.2652 6 20V7Z"
                                            stroke="#EF3826" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>

                                </a>
                            </span>
                            <button *ngIf="data?.isBlocked==true" type="button" (click)="suspendStatus(false,data._id)"
                                class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900 w-[100px]">Unsuspend
                            </button>

                            <button *ngIf="data?.isBlocked==false" type="button" (click)="suspendStatus(true,data._id)"
                                class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 w-[100px]">Suspend
                            </button>

                        </div>
                    </td>
                </tr>

                <!-- <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 shadow-2xl">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <img class="w-10 h-10 rounded-full" src="../../../../assets/images/userneme.png" alt="Jese image">
                    <div class="pl-2">
                        <div class="text-base font-semibold text-gray-900">Neil Sims</div>
                    </div>  
                </th>
                <td class="px-6 py-4">
                    +1 987 4563 210
                </td>
                <td class="px-6 py-4">
                    2972 Westheimer Rd. Santa Ana, Illinois 85486 
                </td>
                <td class="px-6 py-4">
                   U.K
                </td>
                <td class="px-6 py-4">
                    <div class="flex gap-2 items-center">
                        <span class="bg-gray-100 px-2 py-2 rounded-lg">
                            <a (click)="toggleModal()">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 12C21 13.2 16.9706 18 12 18C7.02944 18 3 13.2 3 12C3 10.8 7.02944 6 12 6C16.9706 6 21 10.8 21 12Z" stroke="#30C084" stroke-width="2"/>
                                    <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#30C084" stroke-width="2"/>
                                    </svg>
                                </a>
                            </span>
                            <span class="bg-gray-100 px-2 py-2 rounded-lg">
                                <a href="javascript:;">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 7H19M10 10V18M14 10V18M10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7H9V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3ZM6 7H18V20C18 20.2652 17.8946 20.5196 17.7071 20.7071C17.5196 20.8946 17.2652 21 17 21H7C6.73478 21 6.48043 20.8946 6.29289 20.7071C6.10536 20.5196 6 20.2652 6 20V7Z" stroke="#EF3826" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        
                                    </a>
                                </span>
                        <button type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Suspend </button>
                   
                        
                     
                </div>
                </td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 shadow-2xl">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <img class="w-10 h-10 rounded-full" src="../../../../assets/images/userneme.png" alt="Jese image">
                    <div class="pl-2">
                        <div class="text-base font-semibold text-gray-900">Neil Sims</div>
                    </div>  
                </th>
                <td class="px-6 py-4">
                    +1 987 4563 210
                </td>
                <td class="px-6 py-4">
                    2972 Westheimer Rd. Santa Ana, Illinois 85486 
                </td>
                <td class="px-6 py-4">
                   U.K
                </td>
                <td class="px-6 py-4">
                    <div class="flex gap-2 items-center">
                        <span class="bg-gray-100 px-2 py-2 rounded-lg">
                            <a  (click)="toggleModal()">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 12C21 13.2 16.9706 18 12 18C7.02944 18 3 13.2 3 12C3 10.8 7.02944 6 12 6C16.9706 6 21 10.8 21 12Z" stroke="#30C084" stroke-width="2"/>
                                    <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#30C084" stroke-width="2"/>
                                    </svg>
                                </a>
                            </span>
                            <span class="bg-gray-100 px-2 py-2 rounded-lg">
                                <a href="javascript:;">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 7H19M10 10V18M14 10V18M10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7H9V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3ZM6 7H18V20C18 20.2652 17.8946 20.5196 17.7071 20.7071C17.5196 20.8946 17.2652 21 17 21H7C6.73478 21 6.48043 20.8946 6.29289 20.7071C6.10536 20.5196 6 20.2652 6 20V7Z" stroke="#EF3826" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        
                                    </a>
                                </span>
                        <button type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Suspend </button>
                   
                        
                     
                </div>
                </td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 shadow-2xl">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <img class="w-10 h-10 rounded-full" src="../../../../assets/images/userneme.png" alt="Jese image">
                    <div class="pl-2">
                        <div class="text-base font-semibold text-gray-900">Neil Sims</div>
                    </div>  
                </th>
                <td class="px-6 py-4">
                    +1 987 4563 210
                </td>
                <td class="px-6 py-4">
                    2972 Westheimer Rd. Santa Ana, Illinois 85486 
                </td>
                <td class="px-6 py-4">
                   U.K
                </td>
                <td class="px-6 py-4">
                    <div class="flex gap-2 items-center">
                        <span class="bg-gray-100 px-2 py-2 rounded-lg">
                            <a (click)="toggleModal()">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 12C21 13.2 16.9706 18 12 18C7.02944 18 3 13.2 3 12C3 10.8 7.02944 6 12 6C16.9706 6 21 10.8 21 12Z" stroke="#30C084" stroke-width="2"/>
                                    <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#30C084" stroke-width="2"/>
                                    </svg>
                                </a>
                            </span>
                            <span class="bg-gray-100 px-2 py-2 rounded-lg">
                                <a href="javascript:;">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 7H19M10 10V18M14 10V18M10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7H9V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3ZM6 7H18V20C18 20.2652 17.8946 20.5196 17.7071 20.7071C17.5196 20.8946 17.2652 21 17 21H7C6.73478 21 6.48043 20.8946 6.29289 20.7071C6.10536 20.5196 6 20.2652 6 20V7Z" stroke="#EF3826" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        
                                    </a>
                                </span>
                        <button type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Suspend </button>
                   
                        
                     
                </div>
                </td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 shadow-2xl">
                <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <img class="w-10 h-10 rounded-full" src="../../../../assets/images/userneme.png" alt="Jese image">
                    <div class="pl-2">
                        <div class="text-base font-semibold text-gray-900">Neil Sims</div>
                    </div>  
                </th>
                <td class="px-6 py-4">
                    +1 987 4563 210
                </td>
                <td class="px-6 py-4">
                    2972 Westheimer Rd. Santa Ana, Illinois 85486 
                </td>
                <td class="px-6 py-4">
                   U.K
                </td>
                <td class="px-6 py-4">
                    <div class="flex gap-2 items-center">
                        <span class="bg-gray-100 px-2 py-2 rounded-lg">
                            <a href="javascript:;">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 12C21 13.2 16.9706 18 12 18C7.02944 18 3 13.2 3 12C3 10.8 7.02944 6 12 6C16.9706 6 21 10.8 21 12Z" stroke="#30C084" stroke-width="2"/>
                                    <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#30C084" stroke-width="2"/>
                                    </svg>
                                </a>
                            </span>
                            <span class="bg-gray-100 px-2 py-2 rounded-lg">
                                <a href="javascript:;">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5 7H19M10 10V18M14 10V18M10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7H9V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3ZM6 7H18V20C18 20.2652 17.8946 20.5196 17.7071 20.7071C17.5196 20.8946 17.2652 21 17 21H7C6.73478 21 6.48043 20.8946 6.29289 20.7071C6.10536 20.5196 6 20.2652 6 20V7Z" stroke="#EF3826" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        
                                    </a>
                                </span>
                        <button type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Suspend </button>
                </div>
                </td>
            </tr> -->
            </tbody>
        </table>
        <div class="grid grid-cols-12 mt-3 px-4 items-center">
            <div class="col-span-6">
                <p class="pb-0 mb-0">Showing
                    {{(totalItems > 0 ? ((currentPage - 1) * pageSize + 1) : 0)}} -
                    {{(totalItems <= pageSize ? totalItems : currentPage * pageSize)}} of {{totalItems}} entries </p>
            </div>
            <div class="col-span-6">
                <nav aria-label="Page navigation example">
                    <ul class="flex items-center -space-x-px h-10 text-sm justify-end">
                        <!--  -->
                        <a (click)="previousPage()"
                            class="flex items-center cursor-pointer justify-center px-3 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            <span class="sr-only">Previous</span>
                            <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="M5 1 1 5l4 4" />
                            </svg>
                        </a>
                        <ng-container *ngIf="totalpages <= 5; else limitedPages">
                            <li class="flex cursor-pointer items-center justify-center px-3 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                *ngFor="let pageNumber of pageNumbers" [class.active]="pageNumber === currentPage"
                                (click)="goToPage(pageNumber)">
                                <!-- (click)="goToPage(pageNumber)" -->
                                <span class="page-link">{{pageNumber}}</span>
                            </li>
                        </ng-container>
                        <ng-template #limitedPages>
                            <li class="flex cursor-pointer items-center justify-center px-3 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                *ngFor="let pageNumber of limitedPageNumbers"
                                [class.active]="pageNumber === currentPage" (click)="goToPage(pageNumber)">
                                <!-- (click)="goToPage(pageNumber)" -->
                                <span class="page-link">{{pageNumber}}</span>
                            </li>
                        </ng-template>
                        <li>
                            <!-- (click)="nextPage()" -->
                            <a (click)="nextPage()"
                                class="flex items-center cursor-pointer justify-center px-3 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                <span class="sr-only">Next</span>
                                <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="m1 9 4-4-4-4" />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>


<!---------------modal popup---------------->
<div *ngIf="showModal"
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative my-6 w-1/4 z-50">
        <!--content-->
        <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-4 pb-3 rounded-t">
                <h3 class="text-lg font-semibold">
                    Profile Detail
                </h3>
                <button
                    class="p-1 ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    (click)="detailModal()">
                    <span class="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1_8744)">
                                <path
                                    d="M0.293031 0.792959C0.480558 0.605488 0.734866 0.500173 1.00003 0.500173C1.26519 0.500173 1.5195 0.605488 1.70703 0.792959L8.00003 7.08596L14.293 0.792959C14.3853 0.697449 14.4956 0.621267 14.6176 0.568858C14.7396 0.516449 14.8709 0.488862 15.0036 0.487709C15.1364 0.486555 15.2681 0.511857 15.391 0.562137C15.5139 0.612418 15.6255 0.686671 15.7194 0.780564C15.8133 0.874457 15.8876 0.986109 15.9379 1.10901C15.9881 1.2319 16.0134 1.36358 16.0123 1.49636C16.0111 1.62914 15.9835 1.76036 15.9311 1.88236C15.8787 2.00437 15.8025 2.11471 15.707 2.20696L9.41403 8.49996L15.707 14.793C15.8892 14.9816 15.99 15.2342 15.9877 15.4964C15.9854 15.7586 15.8803 16.0094 15.6948 16.1948C15.5094 16.3802 15.2586 16.4854 14.9964 16.4876C14.7342 16.4899 14.4816 16.3891 14.293 16.207L8.00003 9.91396L1.70703 16.207C1.51843 16.3891 1.26583 16.4899 1.00363 16.4876C0.741433 16.4854 0.49062 16.3802 0.305212 16.1948C0.119804 16.0094 0.014635 15.7586 0.0123566 15.4964C0.0100781 15.2342 0.110873 14.9816 0.293031 14.793L6.58603 8.49996L0.293031 2.20696C0.10556 2.01943 0.000244141 1.76512 0.000244141 1.49996C0.000244141 1.23479 0.10556 0.980487 0.293031 0.792959Z"
                                    fill="black" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_8744">
                                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                </button>
            </div>
            <!--body-->
            <div class="relative p-4 pt-0 flex-auto">
                <div class=' grid grid-cols-12 gap-4'>

                    <div class='col-span-12 text-center bg-transparent '>
                        <div class='bg-white shadow-lg rounded-lg overflow-hidden px-4 pt-4'>
                            <div class='bg-gray-200 h-32 rounded-t-lg'></div>
                            <div class='mx-auto text-center -mt-20'>
                                <img class='rounded-full mx-auto h-36 w-36'
                                    *ngIf="driverDetail.profileImage === null || driverDetail.profileImage === '' || driverDetail.profileImage === undefined"
                                    src='../../../../assets/images/user-vector.svg' />
                                <img *ngIf="driverDetail?.profileImage" class="rounded-full mx-auto h-36 w-36"
                                    [src]="driverDetail.profileImage">

                            </div>
                            <h4 class='font-bold text-lg pt-2'>{{driverDetail?.fullName}}</h4>
                        </div>
                    </div>

                    <div class='col-span-12 bg-white shadow-lg rounded-lg overflow-hidden'>
                        <div class="bg-white ">

                            <div class="tbl ">
                                <table class='w-full'>
                                    <tbody>
                                        <tr class='border-b'>
                                            <th class='py-3 px-5 text-neutral-800 text-sm text-left font-extrabold'>
                                                Email</th>
                                            <td class='px-6 py-3'>{{driverDetail?.email}}</td>
                                        </tr>
                                        <tr class='border-b'>
                                            <td class='py-3 px-5 text-neutral-800 text-sm font-extrabold'>Phone Number
                                            </td>
                                            <td class='px-6 py-3'>{{driverDetail?.mobile}}</td>
                                        </tr>
                                        <tr class='border-b'>
                                            <td class='py-3 px-5 text-neutral-800 text-sm font-extrabold'>Address</td>
                                            <td class='px-6 py-3'>{{driverDetail?.address}}</td>
                                        </tr>
                                        <tr>
                                            <td class='py-3 px-5 text-neutral-800 text-sm font-extrabold'> State </td>
                                            <td class='px-6 py-3'>{{driverDetail?.state}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
</div>

<!---------------modal delete driver---------------->
<div *ngIf="showdeleteModal"
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative my-6 w-1/4 z-50">
        <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div class="flex items-start justify-between p-4 pb-3 rounded-t">
                <button
                    class="p-1 ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    (click)="deleteModal()">
                    <span class="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1_8744)">
                                <path
                                    d="M0.293031 0.792959C0.480558 0.605488 0.734866 0.500173 1.00003 0.500173C1.26519 0.500173 1.5195 0.605488 1.70703 0.792959L8.00003 7.08596L14.293 0.792959C14.3853 0.697449 14.4956 0.621267 14.6176 0.568858C14.7396 0.516449 14.8709 0.488862 15.0036 0.487709C15.1364 0.486555 15.2681 0.511857 15.391 0.562137C15.5139 0.612418 15.6255 0.686671 15.7194 0.780564C15.8133 0.874457 15.8876 0.986109 15.9379 1.10901C15.9881 1.2319 16.0134 1.36358 16.0123 1.49636C16.0111 1.62914 15.9835 1.76036 15.9311 1.88236C15.8787 2.00437 15.8025 2.11471 15.707 2.20696L9.41403 8.49996L15.707 14.793C15.8892 14.9816 15.99 15.2342 15.9877 15.4964C15.9854 15.7586 15.8803 16.0094 15.6948 16.1948C15.5094 16.3802 15.2586 16.4854 14.9964 16.4876C14.7342 16.4899 14.4816 16.3891 14.293 16.207L8.00003 9.91396L1.70703 16.207C1.51843 16.3891 1.26583 16.4899 1.00363 16.4876C0.741433 16.4854 0.49062 16.3802 0.305212 16.1948C0.119804 16.0094 0.014635 15.7586 0.0123566 15.4964C0.0100781 15.2342 0.110873 14.9816 0.293031 14.793L6.58603 8.49996L0.293031 2.20696C0.10556 2.01943 0.000244141 1.76512 0.000244141 1.49996C0.000244141 1.23479 0.10556 0.980487 0.293031 0.792959Z"
                                    fill="black" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_8744">
                                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                </button>
            </div>
            <!--body-->
            <div class="relative p-4 pt-0 flex-auto">
                <div class="w-full flex items-center justify-center">
                    <svg id="fi_9790368" enable-background="new 0 0 512 512" height="90" viewBox="0 0 512 512"
                        width="90" xmlns="http://www.w3.org/2000/svg">
                        <path clip-rule="evenodd"
                            d="m256 512c140.997 0 256-115.003 256-256s-115.003-256-256-256-256 115.003-256 256 115.003 256 256 256z"
                            fill="#f34235" fill-rule="evenodd"></path>
                        <path
                            d="m366.313 171.409h-220.626c-1.246 0-2.434.513-3.291 1.415-.863.909-1.311 2.123-1.246 3.369l11.593 223.69c.513 9.951 8.718 17.747 18.688 17.747h169.137c9.971 0 18.176-7.796 18.688-17.747l11.593-223.69c.065-1.246-.383-2.46-1.246-3.369-.856-.902-2.044-1.415-3.29-1.415zm-182.334 45.095c-.084-3.044 1.026-5.946 3.129-8.166s4.94-3.492 7.984-3.583c6.335-.13 11.593 4.842 11.775 11.126l4.213 157.024c.091 3.038-1.019 5.946-3.129 8.173-2.103 2.214-4.946 3.486-7.997 3.577h-.337c-2.928 0-5.7-1.11-7.848-3.148-2.213-2.103-3.486-4.94-3.57-7.978zm60.577 156.537v-157.044c0-6.316 5.135-11.451 11.444-11.451s11.444 5.135 11.444 11.451v157.044c0 6.322-5.135 11.464-11.444 11.464s-11.444-5.141-11.444-11.464zm79.245.474c-.084 3.051-1.357 5.888-3.57 7.991-2.148 2.038-4.92 3.148-7.848 3.148h-.357c-3.044-.091-5.881-1.363-7.978-3.583-2.11-2.226-3.22-5.128-3.129-8.179l4.213-156.998c.182-6.29 5.401-11.295 11.697-11.139h.078c3.044.091 5.881 1.363 7.984 3.583s3.213 5.122 3.129 8.166zm65.199-237.101v12.048h-266v-12.048c0-6.647 5.407-12.054 12.054-12.054h87.496c2.317 0 4.265-1.746 4.518-4.051 1.617-14.787 14.054-25.939 28.932-25.939s27.315 11.152 28.932 25.939c.253 2.304 2.2 4.051 4.518 4.051h87.496c6.647 0 12.054 5.407 12.054 12.054z"
                            fill="#fff"></path>
                    </svg>
                </div>
                <p class="text-center font-medium text-2xl px-10 pt-6">
                    Are you sure you want to delete this driver account?
                </p>
            </div>
            <div class="flex items-center justify-center p-4 pt-0 rounded-b">
                <button
                    class="text-white bg-red-500 font-bold uppercase text-base px-6 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button" (click)="deleteModal()">
                    No
                </button>
                <button
                    class="text-white bg-green-500 font-bold uppercase text-base px-6 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button" (click)="deleteDriver()">
                    Yes
                </button>

            </div>
        </div>
    </div>
    <div *ngIf="showdeleteModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
</div>