<section class="h-screen md:flex p-9 bg-gray-100">
  <!------------left side------>
  <div class="bg-white w-10/12 mx-auto flex flex-wrap rounded-xl overflow-hidden">
    <div class="overflow-hidden w-6/12 md:flex items-center hidden rounded-xl bg-no-repeat bg-cover bg-top"
      style="background-image: url(../../../assets/images/car-img.png);">
      &nbsp;
    </div>
    <!------------right side------>
    <div class="flex w-6/12 justify-center py-10 items-center bg-white">
      <form class="mx-auto my-auto w-2/3" [formGroup]="loginForm">
        <h2 class="pb-2 text-4xl font-bold">Login your account</h2>
        <p class="text-2xl text-gray-500 pb-9">Let's get started</p>
        <div class="mb-5 relative">
          <label for="email" class="block mb-2 text-base font700 text-dark">Email address</label>
          <input type="email" formControlName="email"
            class="bg-slate-50 border border-slate-700 pl-12 h-14 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            placeholder="Email" />
          <span class="absolute top-12 left-4">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22 12.98V15.5C22 19 20 20.5 17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5"
                stroke="#8F95B2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="#8F95B2" stroke-width="1.5"
                stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <div *ngIf="!loginForm.controls?.['email']?.valid && loginForm.controls?.['email']?.dirty">
            <div *ngIf="loginForm.controls?.['email']?.hasError('invalidEmail') && loginForm.controls?.['email']?.value"
             class="text-red-500">Invalid email !</div>
            <div class="text-red-500" *ngIf="loginForm.controls?.['email']?.hasError('required')">
              This field is Mandatory !</div>
          </div>
        </div>
        <div class="mb-5 relative">
          <label for="password" class="block mb-2 text-base font700  text-dark">Password</label>
          <input [type]="passwordType" id="password" formControlName="password"
            class="border border-slate-700 h-14 pl-12 text-gray-900 value:text-3xl placeholder:text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Password" />
          <span class="absolute top-12 left-4">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10" stroke="#8F95B2" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.5 16C9.5 17.38 10.62 18.5 12 18.5C13.38 18.5 14.5 17.38 14.5 16C14.5 14.62 13.38 13.5 12 13.5"
                stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M22 17V15C22 11 21 10 17 10H7C3 10 2 11 2 15V17C2 21 3 22 7 22H17C18.76 22 19.94 21.81 20.71 21.25"
                stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
          <span class="eye_on" (click)="hidesShowpassword()"><img src="{{passwordIcon}}" alt="" /></span>
          <!-- <span class="absolute top-12 right-3">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.5299 9.46998L9.46992 14.53C8.81992 13.88 8.41992 12.99 8.41992 12C8.41992 10.02 10.0199 8.41998 11.9999 8.41998C12.9899 8.41998 13.8799 8.81998 14.5299 9.46998Z"
                stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M5.59984 17.76C4.59984 16.9 3.68984 15.84 2.88984 14.59C1.98984 13.18 1.98984 10.81 2.88984 9.4C4.06984 7.55 5.50984 6.1 7.11984 5.13"
                stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998" stroke="#8F95B2" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M8.41992 19.53C9.55992 20.01 10.7699 20.27 11.9999 20.27C15.5299 20.27 18.8199 18.19 21.1099 14.59C22.0099 13.18 22.0099 10.81 21.1099 9.39999C20.7799 8.87999 20.4199 8.38999 20.0499 7.92999"
                stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15.5104 12.7C15.2504 14.11 14.1004 15.26 12.6904 15.52" stroke="#8F95B2" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.47 14.53L2 22" stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M22.0003 2L14.5303 9.47" stroke="#8F95B2" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span> -->
          <div *ngIf="!loginForm.controls?.['password']?.valid && loginForm.controls?.['password']?.dirty"
            class="error_main_div">
            <div *ngIf="loginForm.controls?.['password']?.hasError('required')"class="text-red-500">This field is
              Mandatory !</div>
          </div>

        </div>

        <button (click)="dashboard()" type="submit" [disabled]="!loginForm.valid" (keyup.enter)="dashboard()"
          class="text-white cursor-pointer h-14 font500 text-base shadow-lg bg-blue hover:bg-hover_blue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg w-full px-5 py-2.5 text-center mt-4">
          Log In
        </button>

      </form>
    </div>
  </div>
</section>