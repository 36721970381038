<div class="heading_top pl-8 text-2xl">
    <h1>CSV Management</h1>
</div>
<div class="mx-auto w-11/12 pt-11">
    <div class="flex align-items-center justify-between">
        <nav class="flex" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                <li class="inline-flex items-center">
                    <a href="/dashboard"
                        class="inline-flex gap-2 items-center text-base font-medium text-gray hover:text-hover_blue dark:text-gray-400">
                        <svg class="w-3 h-3 pr-3.9" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                        </svg>
                        Dashboard
                    </a>
                </li>
                <li class="inline-flex items-center">
                    <a href="/dashboard/CSV"
                        class="inline-flex gap-2 items-center text-base font-medium text-gray hover:text-hover_blue dark:text-gray-400">
                        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m1 9 4-4-4-4" />
                        </svg>
                        CSV Management
                    </a>
                </li>
            </ol>
        </nav>

        <button *ngIf="activetab=='shipper'" class="bg-[#000e34] px-6 py-3 text-white rounded-xl hover:bg-[#000]"
            (click)="toggleModal()">Upload
            CSV</button>
    </div>



    <!-- Tabs -->
    <ul id="tabs" class="inline-flex pt-2 w-full border-b mt-10">
        <li (click)="activeTab('shipper')"
            class="dark_bg_two  px-9 text-lg bg-gray-200 font-semibold py-2 rounded-t-lg border-t border-r border-l -mb-px">
            <a id="default-tab" href="#Shipper">Shippers/ Receivers</a>
        </li>
        <li (click)="activeTab('request')"
            class="text-gray-800 font-semibold bg-gray-200 text-lg py-2 rounded-t-lg px-9"><a href="#Recievers">Requests
            </a></li>
    </ul>


    <div id="tab-contents">
        <div id="Shipper">
            <div class="relative overflow-x-auto bg-white shadow-lg">
                <div class="grid grid-cols-12 p-5 items-center">
                    <div class="col-span-6">
                        <div class="flex items-center gap-2">
                            <span>Show</span>
                            <!--  (change)="updatePageSize($event.target)" -->
                            <span><select class="w-16 pl-2 h-8" aria-label="Default select example"
                                    [(ngModel)]="selectedLimit" (change)="updatePageSize($event.target)">
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>

                                </select>
                            </span>
                            <span>Entries</span>
                        </div>
                    </div>
                    <div class="col-span-6">
                        <div class="mb-3 flex items-center w-50 justify-end gap-3">
                            <label class="pe-3">Search</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" placeholder="Search name...."
                                    [(ngModel)]="searchTerm" (keyup.enter)="searchQuery()">
                            </div>
                        </div>
                    </div>
                </div>
                <table
                    class="display w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-hidden">
                    <thead class="text-xs text-gray-700 uppercase bg-dark_bg dark:dark_bg dark:dark_bg">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-base text-nowrap capitalize">
                                Company Name
                            </th>

                            <th scope="col" class="px-6 text-base text-nowrap capitalize">
                                Address
                            </th>
                            <th scope="col" class="px-6 py-3 text-base text-nowrap capitalize">
                                City
                            </th>
                            <th scope="col" class="px-6 py-3 text-base text-nowrap capitalize">
                                State
                            </th>
                            <th scope="col" class="px-6 py-3 text-base text-nowrap capitalize">
                                Post code
                            </th>
                            <!-- <th scope="col" class="px-6 py-3 text-base text-nowrap capitalize">
                                    Action
                                </th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b dark:border-gray-700 shadow-2xl" *ngFor="let data of csvArray">
                            <th scope="row"
                                class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">

                                <!-- <img class="w-10 h-10 rounded-full" src="../../../../assets/images/user-vector.svg"> -->
                                <div class="pl-2">
                                    <div class="text-base font-semibold text-gray-900">{{data?.companyName}}</div>
                                </div>
                            </th>
                            <td class="px-6 py-4 font-semibold text-gray-900">
                                {{data?.address}}
                            </td>
                            <td class="px-6 py-4 font-semibold text-gray-900">
                                {{data?.city}}
                            </td>
                            <td class="px-6 py-4 font-semibold text-gray-900">
                                {{data?.state}}
                            </td>
                            <td class="px-6 py-4 font-semibold text-gray-900">
                                {{data?.zipcode}}
                            </td>
                            <!-- <td class="px-6 py-4">
                                    <div class="flex gap-2 items-center">
                                        <button class="bg-[#000e34] px-4 text-nowrap py-3 text-white rounded-xl hover:bg-[#000]" [routerLink]="['reciver']">View detail</button>
                                    </div>
                                </td> -->
                        </tr>
                    </tbody>
                </table>
                <div class="grid grid-cols-12 mt-3 px-4 items-center">
                    <div class="col-span-6">
                        <p class="pb-0 mb-0">Showing
                            {{(totalItems > 0 ? ((currentPage - 1) * pageSize + 1) : 0)}} -
                            {{(totalItems <= pageSize ? totalItems : currentPage * pageSize)}} of {{totalItems}} entries </p>
                    </div>
                    <div class="col-span-6">
                        <nav aria-label="Page navigation example">
                            <ul class="flex items-center -space-x-px h-10 text-sm justify-end">
                                <!--  -->
                                <a (click)="previousPage()"
                                    class="flex items-center cursor-pointer justify-center px-3 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                    <span class="sr-only">Previous</span>
                                    <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="M5 1 1 5l4 4" />
                                    </svg>
                                </a>
                                <ng-container *ngIf="totalpages <= 5; else limitedPages">
                                    <li class="flex cursor-pointer items-center justify-center px-3 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                        *ngFor="let pageNumber of pageNumbers" [class.active]="pageNumber === currentPage" (click)="goToPage(pageNumber)">
                                        <!-- (click)="goToPage(pageNumber)" -->
                                        <span class="page-link">{{pageNumber}}</span>
                                    </li>
                                </ng-container>
                                <ng-template #limitedPages>
                                    <li class="flex cursor-pointer items-center justify-center px-3 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                        *ngFor="let pageNumber of limitedPageNumbers"
                                        [class.active]="pageNumber === currentPage" (click)="goToPage(pageNumber)">
                                        <!-- (click)="goToPage(pageNumber)" -->
                                        <span class="page-link">{{pageNumber}}</span>
                                    </li>
                                </ng-template>
                                <li>
                                    <!-- (click)="nextPage()" -->
                                    <a (click)="nextPage()"
                                        class="flex items-center cursor-pointer justify-center px-3 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                        <span class="sr-only">Next</span>
                                        <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div> 
            </div>
        </div>
        <div id="Recievers" class="hidden">
            <div class="relative overflow-x-auto bg-white shadow-lg">
                <div class="grid grid-cols-12 p-5 items-center">
                    <div class="col-span-6">
                        <div class="flex items-center gap-2">
                            <span>Show</span>
                            <!--  (change)="updatePageSize($event.target)" -->
                            <span><select class="w-16 pl-2 h-8" aria-label="Default select example"
                                    [(ngModel)]="selectedLimit" (change)="updatePageSize($event.target)">
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>

                                </select>
                            </span>
                            <span>Entries</span>
                        </div>
                    </div>
                    <div class="col-span-6">
                        <div class="mb-3 flex items-center w-50 justify-end gap-3">
                            <label class="pe-3">Search</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control" placeholder="Search name...."
                                    [(ngModel)]="searchTerm" (keyup.enter)="searchQuery()">
                            </div>
                        </div>
                    </div>
                </div>
           
            <table
                class="display w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-hidden">
                <thead class="text-xs text-gray-700 uppercase bg-dark_bg dark:dark_bg dark:dark_bg">
                    <tr>
                        <th scope="col" class="px-6 py-3 text-base text-nowrap capitalize">
                            Company Name
                        </th>

                        <th scope="col" class="px-6 text-base text-nowrap capitalize">
                            Address
                        </th>
                        <th scope="col" class="px-6 py-3 text-base text-nowrap capitalize">
                            City
                        </th>
                        <th scope="col" class="px-6 py-3 text-base text-nowrap capitalize">
                            State
                        </th>
                        <th scope="col" class="px-6 py-3 text-base text-nowrap capitalize">
                            Post code
                        </th>
                        <th scope="col" class="px-6 py-3 text-base text-nowrap capitalize">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of csvArray" class="bg-white border-b dark:border-gray-700 shadow-2xl">
                        <th scope="row"
                            class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">

                            <!-- <img class="w-10 h-10 rounded-full" src="../../../../assets/images/user-vector.svg"> -->
                            <div class="pl-2">
                                <div class="text-base font-semibold text-gray-900">{{data?.companyName}}</div>
                            </div>
                        </th>
                        <td class="px-6 py-4 font-semibold text-gray-900">
                            {{data?.address}}
                        </td>
                        <td class="px-6 py-4 font-semibold text-gray-900">
                            {{data?.city}}
                        </td>
                        <td class="px-6 py-4 font-semibold text-gray-900">
                            {{data?.state}}
                        </td>
                        <td class="px-6 py-4 font-semibold text-gray-900">
                            {{data?.zipcode}}
                        </td>
                        <td class="px-6 py-4">
                            <div class="flex gap-2 items-center">
                                <button class="bg-[#000e34] px-4 text-nowrap py-3 text-white rounded-xl hover:bg-[#000]"
                                    [routerLink]="['reciver',data?._id]">View detail</button>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
            <div class="grid grid-cols-12 mt-3 px-4 items-center">
                <div class="col-span-6">
                    <p class="pb-0 mb-0">Showing
                        {{(totalItems > 0 ? ((currentPage - 1) * pageSize + 1) : 0)}} -
                        {{(totalItems <= pageSize ? totalItems : currentPage * pageSize)}} of {{totalItems}} entries </p>
                </div>
                <div class="col-span-6">
                    <nav aria-label="Page navigation example">
                        <ul class="flex items-center -space-x-px h-10 text-sm justify-end">
                            <!--  -->
                            <a (click)="previousPage()"
                                class="flex items-center cursor-pointer justify-center px-3 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                <span class="sr-only">Previous</span>
                                <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M5 1 1 5l4 4" />
                                </svg>
                            </a>
                            <ng-container *ngIf="totalpages <= 5; else limitedPages">
                                <li class="flex cursor-pointer items-center justify-center px-3 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    *ngFor="let pageNumber of pageNumbers" [class.active]="pageNumber === currentPage" (click)="goToPage(pageNumber)">
                                    <!-- (click)="goToPage(pageNumber)" -->
                                    <span class="page-link">{{pageNumber}}</span>
                                </li>
                            </ng-container>
                            <ng-template #limitedPages>
                                <li class="flex cursor-pointer items-center justify-center px-3 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    *ngFor="let pageNumber of limitedPageNumbers"
                                    [class.active]="pageNumber === currentPage" (click)="goToPage(pageNumber)">
                                    <!-- (click)="goToPage(pageNumber)" -->
                                    <span class="page-link">{{pageNumber}}</span>
                                </li>
                            </ng-template>
                            <li>
                                <!-- (click)="nextPage()" -->
                                <a (click)="nextPage()"
                                    class="flex items-center cursor-pointer justify-center px-3 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                    <span class="sr-only">Next</span>
                                    <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div> 
        </div>
    </div>
    </div>

</div>


<!-- modal eplay message -->
<div *ngIf="showModal==true"
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
    <div class="relative my-6 w-1/4 z-50">
        <!--content-->
        <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-4 pb-3 rounded-t">
                <h3 class="text-lg font-semibold">
                    Upload CSV
                </h3>
                <button
                    class="p-1 ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    (click)="toggleModal()">
                    <span class="text-black h-6 w-6 text-2xl block outline-none focus:outline-none"
                        (click)="csvData=[]">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1_8744)">
                                <path
                                    d="M0.293031 0.792959C0.480558 0.605488 0.734866 0.500173 1.00003 0.500173C1.26519 0.500173 
                  1.5195 0.605488 1.70703 0.792959L8.00003 7.08596L14.293 0.792959C14.3853 0.697449 14.4956 0.621267
                   14.6176 0.568858C14.7396 0.516449 14.8709 0.488862 15.0036 0.487709C15.1364 0.486555 15.2681 0.511857 
                   15.391 0.562137C15.5139 0.612418 15.6255 0.686671 15.7194 0.780564C15.8133 0.874457 15.8876 0.986109 
                   15.9379 1.10901C15.9881 1.2319 16.0134 1.36358 16.0123 1.49636C16.0111 1.62914 15.9835 1.76036 15.9311
                    1.88236C15.8787 2.00437 15.8025 2.11471 15.707 2.20696L9.41403 8.49996L15.707 14.793C15.8892 14.9816 
                    15.99 15.2342 15.9877 15.4964C15.9854 15.7586 15.8803 16.0094 15.6948 16.1948C15.5094 16.3802 15.2586 16.4854 14.9964 16.4876C14.7342 16.4899 14.4816 16.3891 14.293 16.207L8.00003 9.91396L1.70703 16.207C1.51843 16.3891 1.26583 16.4899 1.00363 16.4876C0.741433 16.4854 0.49062 16.3802 0.305212 16.1948C0.119804 16.0094 0.014635 15.7586 0.0123566 15.4964C0.0100781 15.2342 0.110873 14.9816 0.293031 14.793L6.58603 8.49996L0.293031 2.20696C0.10556 2.01943 0.000244141 1.76512 0.000244141 1.49996C0.000244141 1.23479 0.10556 0.980487 0.293031 0.792959Z"
                                    fill="black" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_8744">
                                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                </button>
            </div>
            <!--body-->
            <div class="relative p-4 pt-0 flex-auto">
                <div class="mb-3 border-2 border-zinc-300 p-6 rounded-lg justify-center">
                    <input class="form-control" type="file" id="formFile" (change)="convertCsvToJson($event)">
                </div>
            </div>
            <!--footer-->
            <div class="flex items-center justify-end p-4 pt-0 rounded-b">
                <button (click)="submit()" class="text-white h-14 font500 text-base bg-blue hover:bg-blue-800 focus:ring-4 focus:outline-none 
          focus:ring-blue-300 font-medium rounded-lg w-full px-5 py-2.5 text-center" type="submit">
                    Submit
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="showModal==true" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
</div>