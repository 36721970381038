import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx'; 
@Injectable({
  providedIn: 'root'
})
export class ConvertcsvjsonService {

  userArray: any = [];
  csvContent: any;
  convertedArray!: any[];
  properties: any;
  finalArray: any = [];

  fileToDownload: any;
  image: any;

  constructor() { }


  // onFileSelect(input: any): any {
  //   this.finalArray = [];
  //   this.fileToDownload = input.target.files[0];
  //   const files = input.target.files;
  //   var fileTypes = ['csv']; //acceptable file types


  //   if (files && files.length) {

  //     var extension = input.target.files[0].name.split('.').pop().toLowerCase(), //file extension from input file
  //       //Validating type of File Uploaded
  //       isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types
  //     var that = this;
  //     //Flag to check the Validation Result
  //     if (isSuccess) {
  //       const fileToRead = files[0];

  //       const fileReader = new FileReader();

  //       fileReader.onload = async function (fileLoadedEvent: any) {
  //         const textFromFileLoaded = fileLoadedEvent.target.result;
  //         that.csvContent = textFromFileLoaded;

  //         //Flag is for extracting first line
  //         let flag = false;
  //         // Main Data
  //         let objarray: Array<any> = [];
  //         //Properties
  //         let prop: Array<any> = [];
  //         //Total Length
  //         let size: any = 0;

  //         for (const line of that.csvContent.split(/[\r\n]+/)) {
  //           if (flag) {
  //             let obj: any = {};
  //             for (let k = 0; k < size; k++) {
  //               //Dynamic Object Properties
  //               obj[prop[k]] = line.split(',')[k];
  //             }
  //             objarray.push(obj);
  //           } else {
  //             //First Line of CSV will be having Properties
  //             for (let k = 0; k < line.split(',').length; k++) {
  //               size = line.split(',').length;
  //               //Removing all the spaces to make them usefull
  //               prop.push(line.split(',')[k].replace(/ /g, ''));
  //             }
  //             flag = true;
  //           }
  //         }
  //         that.convertedArray = objarray;
  //         that.properties = [];
  //         that.properties = prop;

  //         let finalResult = {
  //           properties: that.properties,
  //           result: that.convertedArray,
  //         };
  //         that.mergeResponse(that.properties, that.convertedArray)
  //         debugger
  //         sessionStorage.setItem('array',JSON.stringify(that.convertedArray))
  //       };
  //       fileReader.readAsText(fileToRead, 'UTF-8');
  //       return { res: this.finalArray }
  //     } else {
  //     }
  //   }
  // }


onFileSelect(input: any): any {
  this.finalArray = [];
  this.fileToDownload = input.target.files[0];
  const files = input.target.files;
  var fileTypes = ['csv', 'xlsx', 'xls']; //acceptable file types

  if (files && files.length) {
    var extension = input.target.files[0].name.split('.').pop().toLowerCase(); //file extension from input file
    //Validating type of File Uploaded
    var isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types
    var that = this;

    if (isSuccess) {
      const fileToRead = files[0];
      const fileReader = new FileReader();

      // Different handling based on file type
      if (extension === 'csv') {
        fileReader.onload = async function (fileLoadedEvent: any) {
          const textFromFileLoaded = fileLoadedEvent.target.result;
          that.csvContent = textFromFileLoaded;

          let flag = false;
          let objarray: Array<any> = [];
          let prop: Array<any> = [];
          let size: any = 0;

          for (const line of that.csvContent.split(/[\r\n]+/)) {
            if (flag) {
              let obj: any = {};
              for (let k = 0; k < size; k++) {
                obj[prop[k]] = line.split(',')[k];
              }
              objarray.push(obj);
            } else {
              for (let k = 0; k < line.split(',').length; k++) {
                size = line.split(',').length;
                prop.push(line.split(',')[k].replace(/ /g, ''));
              }
              flag = true;
            }
          }
          that.convertedArray = objarray;
          that.properties = prop;

          let finalResult = {
            properties: that.properties,
            result: that.convertedArray,
          };
          that.mergeResponse(that.properties, that.convertedArray);
          sessionStorage.setItem('array', JSON.stringify(that.convertedArray));
        };
        fileReader.readAsText(fileToRead, 'UTF-8');
      } else {
        // Handling XLSX or XLS files
        fileReader.onload = async function (fileLoadedEvent: any) {
          const data = new Uint8Array(fileLoadedEvent.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];

          // Convert sheet to JSON
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          let prop:any = jsonData[0];
          let objarray = jsonData.slice(1).map((row: any) => {
            let obj: any = {};
            prop.forEach((key: any, index: number) => {
              obj[key] = row[index];
            });
            return obj;
          });

          that.convertedArray = objarray;
          that.properties = prop;

          let finalResult = {
            properties: that.properties,
            result: that.convertedArray,
          };
          that.mergeResponse(that.properties, that.convertedArray);
          sessionStorage.setItem('array', JSON.stringify(that.convertedArray));
        };
        fileReader.readAsArrayBuffer(fileToRead);
      }
      return { res: this.finalArray };
    } else {
      alert('Please select a valid file (CSV, XLSX, or XLS).');
    }
  }
}



  mergeResponse(header: any, value: any) {
    for (let h = 0; h < header.length; h++) {
      this.finalArray.push({ 'heading': header[h] })
      let headerstate: any = [];
      for (let g = 0; g < value.length; g++) {
        headerstate.push(value[g][header[h]])
        this.finalArray[h].value = headerstate
      }
    }

  }


  setImage(file:any){
 this.image = file
  }

  getImage(){
    return this.image
  }

}
