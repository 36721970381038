import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/commonservice/api/apiservice';
import { MessagehandlingService } from 'src/app/commonservice/message/message-service';
declare var $: any
@Component({
  selector: 'app-query-management',
  templateUrl: './query-management.component.html',
  styleUrls: ['./query-management.component.scss']
})
export class QueryManagementComponent implements OnInit {
  showModal = false;
  showModal2 = false;
  selectedLimit: any = 10
  searchTerm: any;
  totalItems: any;
  currentPage: any = 1
  pageSize: any = 10
  totalpages!: any
  pageNumbers: any = 1
  limitedPageNumbers: any = []
  queryManage: any[] = []
  replyData: any = []
  completeMessage: any;
  emailData: any;
  id: any;
  constructor(private route: Router, private api: ApiService, private message: MessagehandlingService) {
  }

  ngOnInit(): void {
    this.getQueryData()
  }


  updatePageSize(newPageSize: any) {
    this.pageSize = newPageSize.value;
    this.currentPage = 1;
    if (this.searchTerm) {
      this.api.queryManagementData(0, this.pageSize, this.searchTerm).subscribe((res: any) => {
        this.queryManage = res.data.allQuestions;
        if (res.data.totalCount >= this.pageSize) {
          this.totalItems = res.data.totalCount;
        } else {
          this.totalItems = this.pageSize;
        }
        this.totalpages = Math.ceil(this.totalItems / this.pageSize);
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      });
    } else {
      this.api.queryManagementData(0, this.pageSize).subscribe((res: any) => {
        this.queryManage = res.data.allQuestions;
        if (res.data.totalCount >= this.pageSize) {
          this.totalItems = res.data.totalCount;
        } else {
          this.totalItems = this.pageSize;
        }
        this.totalpages = Math.ceil(this.totalItems / this.pageSize);
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      });
    }
  }


  toggleModal(email?: any,id?:any) {
    this.emailData = email
    this.id=id
    this.showModal = !this.showModal;
    this.replyData=''
  }

  toggleModal2(data?: any) {
    this.showModal2 = !this.showModal2;
    this.completeMessage = data
  }

  getQueryData() {
    const offset = (this.currentPage - 1) * this.pageSize;
    this.api.queryManagementData(offset, this.selectedLimit).subscribe((res: any) => {
      if (Array.isArray(res.data.allQuestions)) {
        this.queryManage = res.data.allQuestions;
      this.totalItems = res.data.totalCount;
      this.totalpages = Math.ceil(this.totalItems / this.pageSize);
      }
    })
  }




  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      const offset = (this.currentPage - 1) * this.pageSize;

      if (this.searchTerm) {
        this.api.queryManagementData(offset, this.pageSize, this.searchTerm).subscribe((res: any) => {
          this.queryManage = res.data.allQuestions;
          this.updateLimitedPageNumbers();
          this.updatePageNumbersArray();
        });
      } else {
        this.api.queryManagementData(offset, this.pageSize).subscribe((res: any) => {
          this.queryManage = res.data.allQuestions;
          this.updateLimitedPageNumbers();
          this.updatePageNumbersArray();
        });
      }
    }
  }

  nextPage() {
    if (this.currentPage < this.totalpages) {
      this.currentPage++;
      const offset = (this.currentPage - 1) * this.pageSize;

      if (this.searchTerm) {
        this.api.queryManagementData(offset, this.pageSize, this.searchTerm).subscribe((res: any) => {
          this.queryManage = res.data.allQuestions;
          this.updateLimitedPageNumbers();
          this.updatePageNumbersArray();
        });
      } else {
        this.api.queryManagementData(offset, this.pageSize).subscribe((res: any) => {
          this.queryManage = res.data.allQuestions;
          this.updateLimitedPageNumbers();
          this.updatePageNumbersArray();
        });
      }
    }
  }

  goToPage(pageNumber: number) {
    this.currentPage = pageNumber;
    const offset = (this.currentPage - 1) * this.pageSize;
    if (this.searchTerm) {
      this.api.queryManagementData(offset, this.pageSize, this.searchTerm).subscribe((res: any) => {
        this.queryManage = res.data.allQuestions;
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      });
    } else {
      this.api.queryManagementData(offset, this.pageSize).subscribe((res: any) => {
        this.queryManage = res.data.allQuestions;
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      });
    }
  }


  searchQuery() {
    this.currentPage = 1;
    let offset = 0
    this.api.queryManagementData(offset, this.pageSize, this.searchTerm).subscribe((res: any) => {
      this.queryManage = res.data.allQuestions
      this.totalItems = res.data.totalCount;
      if (res.data.totalCount) {
        this.totalpages = Math.ceil(this.totalItems / this.pageSize)
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      }
    })
  }

  updateLimitedPageNumbers() {
    this.limitedPageNumbers = this.calculateLimitedPageNumbers(this.currentPage, this.totalpages);
  }

  updatePageNumbersArray() {
    this.pageNumbers = [];
    for (let i = 1; i <= this.totalpages; i++) {
      this.pageNumbers.push(i);
    }
  }

  calculateLimitedPageNumbers(currentPage: number, totalpages: number): number[] {
    const numPagesToShow = 2;
    const limitedPageNumbers: number[] = [];
    limitedPageNumbers.push(currentPage);
    for (let i = 1; i <= numPagesToShow; i++) {
      if (currentPage - i > 0) {
        limitedPageNumbers.unshift(currentPage - i);
      }
    }
    for (let i = 1; i <= numPagesToShow; i++) {
      if (currentPage + i <= totalpages) {
        limitedPageNumbers.push(currentPage + i);
      }
    }
    return limitedPageNumbers;
  }

  sendReply() {
    if (this.replyData.trim() !== '') {
      let data = {
        email: this.emailData,
        id:this.id,
        message: this.replyData
      }
      this.api.sendReply(data).subscribe((res: any) => {
        this.toggleModal()
        this.message.successMessage(res.message,1000)
        this.getQueryData()
      });
    } else
      this.message.successMessage('Please enter message.', 1000);
  
  }
  

}
