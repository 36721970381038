import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor() { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    var token:any = localStorage.getItem('tokenC') 
    if (token) {
      const authReq = request.clone({

        headers: new HttpHeaders({
          // Content-Type:'application/json'
         // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      });
      return next.handle(authReq);
    } 
    else {
      const authReq = request.clone({

        // headers: new HttpHeaders({
        // })
      });
      return next.handle(authReq);
    }
  }
}
