import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/commonservice/api/apiservice';
import { MessagehandlingService } from 'src/app/commonservice/message/message-service';
import { ConvertcsvjsonService } from 'src/app/commonservice/services/convertcsvjson.service';
declare var $: any
@Component({
  selector: 'app-csv-management',
  templateUrl: './csv-management.component.html',
  styleUrls: ['./csv-management.component.scss']
})
export class CSVManagementComponent {
  showModal = false;
  selectedLimit: any = 10
  searchTerm: any;
  totalItems: any;
  currentPage: any = 1
  pageSize: any = 10
  totalpages!: any
  pageNumbers: any = 1
  limitedPageNumbers: any = []
  conditioncheck: boolean = true;
  csvData: any = [];
  arrayCheck: any = [];
  apiParams: any = [];
  checkStatus: boolean = true;
  length: any;
  csvArray: any = []
  activetab: any = 'shipper'

  constructor(private csvtojson: ConvertcsvjsonService, private ref: ChangeDetectorRef, private api: ApiService, private message: MessagehandlingService) { }
  // tabs

  ngOnInit(): void {
    let tabsContainer: any = document.querySelector('#tabs');
    let tabTogglers: any = tabsContainer.querySelectorAll('#tabs a');
    tabTogglers.forEach(function (toggler: any) {
      toggler.addEventListener('click', function (e: any) {
        e.preventDefault();
        let tabName: any = this.getAttribute('href');
        let tabContents: any = document.querySelector('#tab-contents');
        for (let i = 0; i < tabContents.children.length; i++) {
          tabTogglers[i].parentElement.classList.remove(
            'border-t',
            'border-r',
            'border-l',
            '-mb-px',
            'dark_bg_two',
            'white_text'
          );
          tabContents.children[i].classList.remove('hidden');
          if ('#' + tabContents.children[i].id === tabName) {
            continue;
          }
          tabContents.children[i].classList.add('hidden');
        }
        e.target.parentElement.classList.add(
          'border-t',
          'border-r',
          'border-l',
          '-mb-px',
          'dark_bg_two',
          'white_text'
        );
      });
    });
    this.getCSVData()
  }

  activeTab(type: any) {
    this.activetab = type
    this.getCSVData()
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  getCSVData() {
    const offset = (this.currentPage - 1) * this.pageSize;
    this.api.csvManagementData(offset, this.selectedLimit, this.activetab).subscribe((res: any) => {
      if (Array.isArray(res.data.allShipper)) {
        this.csvArray = res.data.allShipper;
        this.totalItems = res.data.totalCount;
        this.totalpages = Math.ceil(this.totalItems / this.pageSize);
      }
    })
  }


  convertCsvToJson(event: any) {
    const fileName = event.target.files[0].name;
    const fileExtension = fileName.split('.').pop();

    if (fileExtension !== 'csv' && fileExtension !== 'xlsx' && fileExtension !== 'xls') {
      debugger
      this.message.errorMessage('Please select a CSV file.', 1000);
      return;
    } else {
      debugger
      this.csvData = this.csvtojson.onFileSelect(event).res;
      setTimeout(() => {
        this.length = this.csvData[0].value.filter((val: any) => val !== "").length;
      }, 100);
    }
  }

  normalizeKey = (key: any): string => {
    return key.trim().replace(/\s+/g, '').toLowerCase(); // Normalize key (trim, remove spaces, lower case)
  };



  submit() {
    debugger
    let arraycheck = JSON.parse(sessionStorage.getItem('array') || '[]');
    console.log(arraycheck);
    let a = []
    a.push(arraycheck)
    console.log(a, "Deep");
    arraycheck.slice(0, 5).forEach((item: any, index: number) => {
      console.log(`Item ${index}:`, item);  // Expanding the items for better inspection
    });

    const filteredArray = arraycheck
    .filter((item: any) => {
      // Ensure we are checking for `UniqueID` field before accessing it
      const uniqueIdKey = Object.keys(item).find(k => this.normalizeKey(k) === 'uniqueid');
      return uniqueIdKey && item[uniqueIdKey];  // Check that `UniqueID` exists and is not null/undefined
    })
    .map((item: any) => {
      const uniqueIdKey = Object.keys(item).find(k => this.normalizeKey(k) === 'uniqueid'); // Find the normalized key
  
      // Return the processed object, checking each field before applying trim() or parsing
      return {
        companyName: item.CompanyName || item.Name || "",
        // companyName: typeof item.Name === 'string' ? item.Name.trim() : '',
        city: typeof item.City === 'string' ? item.City.trim() : '',  
        state: typeof item.State === 'string' ? item.State.trim() : '',  
        address: typeof item.Address === 'string' ? item.Address.trim() : '',  
        zipcode: typeof item.Zipcode === 'string' ? item.Zipcode.trim() : item.Zipcode,  
        latitude: typeof item.Latitude === 'string' ? parseFloat(item.Latitude) : item.Latitude,  
        longitude: typeof item.Longitude === 'string' ? parseFloat(item.Longitude) : item.Longitude,  
        uniqueId: uniqueIdKey && typeof item[uniqueIdKey] === 'string' ? item[uniqueIdKey].trim() : '', 
      };
    });
  
  console.log(filteredArray); 
  
    console.log(filteredArray);

    if (filteredArray.length) {
      const formData = {
        users: filteredArray
      };
      this.api.uploadCsv(formData).subscribe((res: any) => {
        if (res.status == 'OK') {
          // this.csvData = [];
          this.toggleModal()
          this.activetab = 'shipper'
          this.getCSVData()
        }
        this.message.successMessage(res.message, 2000);
      });
    }
    else {
      this.message.errorMessage('Please use correct csv format file or upload csv file again!!', 2000)
    }
  }

  updatePageSize(newPageSize: any) {
    this.pageSize = newPageSize.value;
    this.currentPage = 1;
    if (this.searchTerm) {
      this.api.csvManagementData(0, this.pageSize, this.activetab, this.searchTerm).subscribe((res: any) => {
        this.csvArray = res.data.allShipper;
        if (res.data.totalCount >= this.pageSize) {
          this.totalItems = res.data.totalCount;
        } else {
          this.totalItems = this.pageSize;
        }
        this.totalpages = Math.ceil(this.totalItems / this.pageSize);
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      });
    } else {
      this.api.csvManagementData(0, this.pageSize, this.activetab).subscribe((res: any) => {
        this.csvArray = res.data.allShipper;
        if (res.data.totalCount >= this.pageSize) {
          this.totalItems = res.data.totalCount;
        } else {
          this.totalItems = this.pageSize;
        }
        this.totalpages = Math.ceil(this.totalItems / this.pageSize);
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      });
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      const offset = (this.currentPage - 1) * this.pageSize;

      if (this.searchTerm) {
        this.api.csvManagementData(offset, this.pageSize, this.activetab, this.searchTerm).subscribe((res: any) => {
          this.csvArray = res.data.allShipper;
          this.updateLimitedPageNumbers();
          this.updatePageNumbersArray();
        });
      } else {
        this.api.csvManagementData(offset, this.pageSize, this.activetab).subscribe((res: any) => {
          this.csvArray = res.data.allShipper;
          this.updateLimitedPageNumbers();
          this.updatePageNumbersArray();
        });
      }
    }
  }

  nextPage() {
    if (this.currentPage < this.totalpages) {
      this.currentPage++;
      const offset = (this.currentPage - 1) * this.pageSize;

      if (this.searchTerm) {
        this.api.csvManagementData(offset, this.pageSize, this.activetab, this.searchTerm).subscribe((res: any) => {
          this.csvArray = res.data.allShipper;
          this.updateLimitedPageNumbers();
          this.updatePageNumbersArray();
        });
      } else {
        this.api.csvManagementData(offset, this.pageSize, this.activetab).subscribe((res: any) => {
          this.csvArray = res.data.allShipper;
          this.updateLimitedPageNumbers();
          this.updatePageNumbersArray();
        });
      }
    }
  }

  goToPage(pageNumber: number) {
    this.currentPage = pageNumber;
    const offset = (this.currentPage - 1) * this.pageSize;
    if (this.searchTerm) {
      this.api.csvManagementData(offset, this.pageSize, this.activetab, this.searchTerm).subscribe((res: any) => {
        this.csvArray = res.data.allShipper;
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      });
    } else {
      this.api.csvManagementData(offset, this.pageSize, this.activetab).subscribe((res: any) => {
        this.csvArray = res.data.allShipper;
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      });
    }
  }


  searchQuery() {
    this.currentPage = 1;
    let offset = 0
    this.api.csvManagementData(offset, this.pageSize, this.activetab, this.searchTerm).subscribe((res: any) => {
      this.csvArray = res.data.allShipper
      this.totalItems = res.data.totalCount;
      if (res.data.totalCount) {
        this.totalpages = Math.ceil(this.totalItems / this.pageSize)
        this.updateLimitedPageNumbers();
        this.updatePageNumbersArray();
      }
    })
  }

  updateLimitedPageNumbers() {
    this.limitedPageNumbers = this.calculateLimitedPageNumbers(this.currentPage, this.totalpages);
  }

  updatePageNumbersArray() {
    this.pageNumbers = [];
    for (let i = 1; i <= this.totalpages; i++) {
      this.pageNumbers.push(i);
    }
  }

  calculateLimitedPageNumbers(currentPage: number, totalpages: number): number[] {
    const numPagesToShow = 2;
    const limitedPageNumbers: number[] = [];
    limitedPageNumbers.push(currentPage);
    for (let i = 1; i <= numPagesToShow; i++) {
      if (currentPage - i > 0) {
        limitedPageNumbers.unshift(currentPage - i);
      }
    }
    for (let i = 1; i <= numPagesToShow; i++) {
      if (currentPage + i <= totalpages) {
        limitedPageNumbers.push(currentPage + i);
      }
    }
    return limitedPageNumbers;
  }



}
