<div class="heading_top pl-8 text-2xl">
  <h1>Settings</h1>
</div>
<div class="mx-auto w-11/12 pt-11">
  <nav class="flex" aria-label="Breadcrumb">
    <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
      <li class="inline-flex items-center">
        <a href="/dashboard"
          class="inline-flex gap-2 items-center text-base font-medium text-gray hover:text-hover_blue dark:text-gray-400">
          <svg class="w-3 h-3 pr-3.9" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 20 20">
            <path
              d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
          </svg>
          Dashboard
        </a>
      </li>
      <li class="inline-flex items-center">
        <a href="/dashboard/setting"
          class="inline-flex gap-2 items-center text-base font-medium text-gray hover:text-hover_blue dark:text-gray-400">
          <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
            fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m1 9 4-4-4-4" />
          </svg>
          Settings
        </a>
      </li>
    </ol>
  </nav>
  <div class="relative overflow-x-auto mt-8 bg-white rounded-xl shadow-lg pb-3 w-2/4">
    <div class="grid grid-cols-12 p-7" [formGroup]="changePasswordForm">
      <div class="col-span-12 text-2xl border-b-2 pb-5 mb-8">Change Password</div>
      <div class="col-span-12">
        <div class="mb-5 relative">
          <label for="text" class="block mb-2 text-sm  text-dark">Old Password</label>
          <input type="text" class="border border-slate-300 h-14 text-gray-900 text-sm rounded-lg block w-full"
            placeholder="Old Password" formControlName="oldPassword" />
          <div
            *ngIf="!changePasswordForm.controls?.['oldPassword']?.valid && changePasswordForm.controls?.['oldPassword']?.dirty"
            class="error_main_div">
            <div *ngIf="changePasswordForm.controls?.['oldPassword']?.hasError('required')"class="text-red-500">This
              field is mandatory !</div>
          </div>
          <div *ngIf="changePasswordForm.controls?.['oldPassword']?.hasError('leadingTrailingSpaces')"
           class="text-red-500">
            Space are not allowed.
          </div>
        </div>
      </div>
      <div class="col-span-12">
        <div class="mb-5 relative">
          <label for="text" class="block mb-2 text-sm  text-dark">New Password</label>
          <input type="text" class="border border-slate-300 h-14 text-gray-900 text-sm rounded-lg block w-full"
            placeholder="New Password" formControlName="newPassword" />
          <div
            *ngIf="!changePasswordForm.controls?.['newPassword']?.valid && changePasswordForm.controls?.['newPassword']?.dirty"
            class="error_main_div">
            <div *ngIf="changePasswordForm.controls?.['newPassword']?.hasError('required')"class="text-red-500">
              This field is mandatory !</div>
          </div>
          <div *ngIf="changePasswordForm.controls?.['newPassword']?.hasError('leadingTrailingSpaces')"
           class="text-red-500">
            Space are not allowed.</div>
        </div>
      </div>
      <div class="col-span-12">
        <div class="mb-5 relative">
          <label for="text" class="block mb-2 text-sm  text-dark">Confirm Password</label>
          <input type="text" class="border border-slate-300 h-14 text-gray-900 text-sm rounded-lg block w-full"
            placeholder="Confirm Password" formControlName="cPassword" />
          <div
            *ngIf="!changePasswordForm.controls?.['cPassword']?.valid && changePasswordForm.controls?.['cPassword']?.dirty"
            class="error_main_div">
            <div
              *ngIf="changePasswordForm.controls?.['cPassword']?.invalid && changePasswordForm.controls?.['cPassword']?.errors?.['ConfirmPassword']"
             class="text-red-500">Password and confirm password is not match !</div>
            <div *ngIf="changePasswordForm?.controls?.['cPassword']?.hasError('required')"class="text-red-500">
              This field is mandatory !</div>
            <div *ngIf="changePasswordForm?.controls?.['cPassword']?.hasError('leadingTrailingSpaces')"
             class="text-red-500">
              Space are not allowed.</div>
          </div>
        </div>
      </div>
      <div class="col-span-12 pb-1">
        <button type="submit" class="text-white bg-green-800 hover:bg-green-800 focus:ring-4 focus:ring-green-300  
                font-medium rounded-lg text-sm px-5 py-3 dark:bg-green-600 dark:hover:bg-green-700
                 focus:outline-none dark:focus:ring-green-800 mr-4" (click)="submitPassword()"
          [disabled]="changePasswordForm.invalid">Submit</button>
        <button type="submit"
          class="text-white bg-red-800 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5
                     py-3 dark:bg-red-600 dark:hover:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
          (click)="close()">Cancel</button>
      </div>
    </div>
  </div>