
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessagehandlingService } from '../message/message-service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    public message:MessagehandlingService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
 
    }, (error: any) => {
      if (error instanceof HttpErrorResponse) {
        console.log(error.error.statusCode)
        // if(error.error.statusCode == 400){
        //   this.message.successMessage(error.error.message, 'OK')
        // }
        switch (error.error.statusCode) {
          // case 401:
          //   /********** Auto logout if 401 response returned from api **********/
          //   localStorage.clear()
          //   this.message.successMessage('You have been logged out for security purpose. Please login again.', 2000)
          //   this.router.navigate(['/'])
          //   break;
          case 400:
            /********** handle error msg **********/
            console.log(error.error.message)
            this.message.successMessage(error.error.message, 2000)
            break;
          // case 0:
          //   /********** If server dosent respond **********/
          //   this.message.errorMessage(error.error.error, 2000)
          //   break;
          default:
            /********** Check for other serve-side errors **********/
            this.message.successMessage(error.error.message, 2000)
            break;
        }
      }

    }));
  // }

  
}

}

