import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { DashboadinternalComponent } from './dashboadinternal/dashboadinternal.component';
import { DriversAccountComponent } from './drivers-account/drivers-account.component';
import { QueryManagementComponent } from './query-management/query-management.component';
import { SettingComponent } from './setting/setting.component';

const routes: Routes = [{ path:'', component: DashboardComponent,
  children:[
    { path:'', component: DashboadinternalComponent},
    { path:'drivers-account', component: DriversAccountComponent},
    { path: 'shippers-reciever', loadChildren: () => import('./shippers-reciever/shippers-reciever.module').then(m => m.ShippersRecieverModule)},
    { path: 'reviews-given', loadChildren: () => import('./reviews-given/reviews-given.module').then(m => m.ReviewsGivenModule) },
    { path:'query-management', component: QueryManagementComponent},
    { path:'setting', component: SettingComponent},
    { path: 'CSV', loadChildren: () => import('./csv-management/csv-management.module').then(m => m.CSVManagementModule) },
  ]
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
