import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessagehandlingService {
  this: any;

  constructor(private _snackBar: MatSnackBar) { }

  successMessage(message: any, time: any) {
    this._snackBar.open(message, '', {
      duration: time,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'success-snackbar'
    });
  }


  errorMessage(message: any, time: any) {
    this._snackBar.open(message, '', {
      duration: time,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'success-snackbar'
    });
  }


  warningMessage(message: any, time: any) {
    this._snackBar.open(message, '', {
      duration: time,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'warning-snackbar'
    });
  }


}


